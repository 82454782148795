.Footer {
    width: 1346px;
    margin: 128px auto;
    &-line {
        width: 1346px;
        height: 1px;

        background: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.08;
    }

    &-logo {
        margin-top: 100px;
        width: 235x;
        height: 57px;
    }

    &-content {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        &-desc {
            width: calc(50% - 20px);
            font-size: 24px;
            line-height: 120%;
            /* or 29px */

            letter-spacing: -0.06em;

            color: #ffffff;

            opacity: 0.5;

            .btn {
                margin-top: 10px;
                margin-bottom: 20px;
                background: rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(16px);
                padding: 15px 20px;
                border-radius: 36px;
                display: inline-block;
                color: #fff;
                font-size: 16px;
                line-height: 120%;
                cursor: pointer;
            }
        }

        &-menu {
            width: 50%;
            display: flex;
            justify-content: right;
            flex-wrap: wrap;

            font-family: 'Gerbera';
            font-size: 20px;
            line-height: 220%;

            ul {
                padding-left: 50px;
                
            }
        }
    }
}

@media (max-width: 768px) {
    .Footer {
        width: 100%;
        margin: 0 auto;
        padding: 32px;
        &-line {
            width: 100%;
            height: 1px;
    
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.08;
        }
    
        &-logo {
            margin-top: 60px;
            width: 180px;
            height: auto;
        }
    
        &-content {
            margin-top: 24px;
            display: block;
            width: 100%;
    
            &-desc {
                width: 100%;
                font-size: 20px;
                line-height: 120%;
                /* or 29px */
    
                letter-spacing: -0.06em;
    
                color: #ffffff;
    
                opacity: 0.5;
            }
    
            &-menu {
                margin-top: 60px;
                padding-right: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
    
                font-size: 16px;
                line-height: 140%;
    
                ul {
                    padding-left: 0;
                    
                }
            }
        }
    }
}