@keyframes _tips {
    0% {
     transform: translate(-50%, 0);
     opacity: 0;
    }
   
    50% {
     opacity: 1;
    }
   
    100% {
     transform: translate(-50%, 20px);
    }
}

.Down {
    opacity: 0;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    animation: 1.5s ease 0s infinite normal none running _tips;
}

