.btn {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(16px);
    padding: 24px 50px;
    border-radius: 36px;
    display: inline-block;
    color: #fff;
    font-family: 'Gerbera';
    font-size: 20px;
    line-height: 120%;
    cursor: pointer;
}

@media (max-width: 768px) {
    .btn {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(16px);
        padding: 10px 20px;
        border-radius: 16px;
        display: inline-block;
        color: #fff;
        font-family: 'Gerbera';
        font-size: 16px;
        line-height: 100%;
        
    }
}