@font-face {
  font-family: "iconfont"; /* Project id 3504919 */
  src: url('iconfont.woff2?t=1657101540451') format('woff2'),
       url('iconfont.woff?t=1657101540451') format('woff'),
       url('iconfont.ttf?t=1657101540451') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-brand:before {
  content: "\e63b";
}

.icon-numbering-device:before {
  content: "\e63c";
}

.icon-instant-message:before {
  content: "\e63d";
}

.icon-process-operate:before {
  content: "\e63e";
}

.icon-target:before {
  content: "\e641";
}

