.Solution {
    margin: 128px auto 0;
    width: 1346px;
    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    &-item {
        margin-top: 32px;
        width: 400px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(16px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 50px;

        padding: 32px 50px 60px 50px;

        font-size: 20px;
        line-height: 140%;
        &-icon {
            float: left;
            margin-top: 10px;
        }

        &-title {
            margin-top: 1px;
            margin-left: 60px;
            padding-top: 12px;
        }

        &-line {
            margin-top: 20px;
            width: 100%;
            height: 1px;
    
            background: #ffffff;
            mix-blend-mode: normal;
            opacity: 0.08;
        }

        &-desc {
            margin-top: 40px;
            font-size: 20px;
            line-height: 140%;
            /* identical to box height, or 28px */

            letter-spacing: -0.02em;
            opacity: 0.5;
        }
    }
}

@media (max-width: 768px) {
    .Solution {
        margin: 60px auto 0;
        width: 100%;
        &-list {
            display: block;
            padding: 0 24px;
            width: 100%;
        }
    
        &-item {
            margin-top: 32px;
            width: auto;

            font-size: 20px;
            line-height: 140%;

            border-radius: 30px;
            padding: 32px 30px 40px 30px;

            &-icon {
                float: left;
                margin-top: 10px;
                // margin-left: 50px;
            }
    
            &-title {
                margin-top: 1px;
                // margin-left: 120px;
            }
            &-desc {
                margin-top: 40px;
                font-size: 18px;
            }
        }
    }
    
}