.Header {
    margin: 0 auto;
    padding-top: 60px;
    // width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header-logo {
    cursor: pointer;
    margin-left: 150px;
    width: 270px;
    height: 65px;
}
.Header-menu {
    padding-left: 100px;
    padding-right: 100px;
    display: flex;
    align-items: center;
}
.Header-menu li {
    cursor: pointer;
    // width: 124px;
    // height: 28px;
    margin-right: 65px;
    font-size: 20px;
    line-height: 200%;
    /* identical to box height, or 28px */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    opacity: 0.9;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.Header-pc {
    display: flex;
    align-items: center;
}

.Header-mobile {
    display: none;
}

@media (max-width: 768px) {
    .Header {
        justify-content: space-between;
        // padding: 30px 20px 0px;
        position: fixed;
        width: 100%;
        transition: all 0.2s;
        top: 0;
        padding: 20px 0;
        background: #000;
    }

    .Header-pc {
        display: none;
    }

    .Header-logo {
        margin-left: 20px;
        width: 160px;
        height: auto;
    }

    .Header-menu {
        padding-left: 10px;
        padding-right: 20px;
        display: block;
        li {
            margin-right: 0;
            justify-content: right;
        }
        
    }

    .Header-mobile {
        display: block;
        &>div:first-child {
            margin-right: 20px;
        }
    }
    
    .Header-menuwrap {
        position: fixed;
        padding-top: 10px;
        top: 60px;
        right: 0;
        width: 100%;
        background: #000;
        height: 100vh;
        transition: all 0.2s;
        overflow: hidden;
        z-index: 2;
        opacity: 0.9;
    }

    .Header-menu-btn {
        text-align: right;
        padding-right: 20px;
        margin-top: 10px;
    }

    .Header-menu li {
        opacity: 1;
    }
}