.HomeWrap {
    margin: 0 auto;
    background: radial-gradient(62.69% 62.69% at 50% 50%, #223a5f 0%, #ab68d5 47.48%, #133055 100%);
    height: 1000px;
    position: relative;
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        background: url("../images/home-bg.png");
        mix-blend-mode: multiply;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.Home-content {
    position: relative;
    z-index: 1;
    text-align: center;
    margin: 0 auto;
    padding-top: 300px;
    width: 1359px;

    &-title {
        padding: 0 20px;
        font-size: 48px;
        line-height: 120%;
    }

    &-desc {
        padding: 0 20px;
        margin-top: 30px;
        flex-wrap: wrap;
        font-size: 40px;
        line-height: 120%;
        /* or 48px */

        text-align: center;
        letter-spacing: -0.02em;

        color: #ffffff;
    }
    &-btn {
        margin-top: 48px;
    }
}
.HomeListWrap {
    background: #0d0d0d;
    padding-top: 128px;
}

.HomeList {
    margin: 0 auto;
    color: #fff;
    width: 1359px;
    display: flex;

    & > div {
        flex: 1;
        margin-bottom: 58px;
    }

    li {
        display: flex;
        // flex-wrap: wrap;
        margin-bottom: 30px;
        font-size: 24px;
        line-height: 140%;
        /* or 34px */

        letter-spacing: -0.02em;
    }

    li > div:first-child {
        margin-right: 20px;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;

        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(16px);
        border-radius: 16px;

        .iconfont {
            font-size: 24px;
        }
    }

    li > div:last-child {
        flex: 1;
        padding-top: 12px;
    }
}

@media (max-width: 768px) {
    .HomeWrap {
        height: 825px;
    }

    .Home-content {
        position: relative;
        text-align: center;
        padding-top: 246px;
        width: 100%;

        &-title {
            font-size: 36px;
            line-height: 120%;
        }

        &-desc {
            margin-top: 24px;
            flex-wrap: wrap;
            font-size: 20px;
            line-height: 140%;
            /* or 28px */

            text-align: center;
            letter-spacing: -0.02em;

            color: #ffffff;
        }
        &-btn {
            margin-top: 48px;
        }
    }
    .HomeListWrap {
        background: #0d0d0d;
        padding: 60px 24px 0;
    }

    .HomeList {
        margin: 0 auto;
        color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;

        & > div {
            flex: 1;
        }

        li {
            display: flex;
            // flex-wrap: wrap;
            margin-bottom: 30px;
            font-family: "Gerbera";
            font-size: 16px;
            line-height: 140%;
            /* or 34px */

            letter-spacing: -0.02em;
        }

        li > div:first-child {
            margin-right: 20px;
            width: 60px;
            height: 60px;
            text-align: center;
            line-height: 60px;

            background: rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(16px);
            border-radius: 16px;

            .iconfont {
                font-size: 20px;
            }
        }

        li > div:last-child {
            flex: 1;
        }
    }
}
